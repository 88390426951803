<template>
    <modal class="cardAssetRecordModel" :isShow="isShow" @keyup.esc="hide">
        <div class="header">卡资产调整记录</div>
        <div class="modal-body">
            <div class="filter-box">
                <div class="inline-block">
                    <span class="lable-txt">营业日期：</span>
                    <el-date-picker class="from-date"
                        v-model="businessHours"
                        type="date"
                        placeholder=""
                        :default-value="new Date()">
                    </el-date-picker>
                </div>
                <div class="inline-block search-box">
                    <div class="search">
                        <input type="text" placeholder="手机号,卡号，客户姓名模糊检索" v-model="searchText">
                    </div>
                    <div class="bnt-search" @click="loadTable()">搜索</div>
                </div>
            </div>
            <div class="flex-box">
                <div class="data-box">
                    <div class="table-box grey-table" ref="tableBox">
                        <el-table class="el-table--scrollable-y" :data="tablePageList"  height="402px" style="width: 100%;" 
                            highlight-current-row
                            @current-change="currentChange"
                            border
                            v-mouse-scroll="{scrollName:'.el-table__body-wrapper'}">
                            <el-table-column fixed="left" label="序号" width="40">
                                <template #default="scope">
                                    {{scope.$index+1+ ((tablePageIndex-1)*tablePageSize)}}
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" :width="50" >
                                <template #default="scope">
                                    <el-button @click.stop="printReceipt(scope.row)" type="text" size="small">补打</el-button>
                                </template>
                            </el-table-column>
                            <el-table-column prop="Card_No" label="卡号" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="Member_Name" label="姓名" min-width="100" align="left"></el-table-column>
                            <el-table-column prop="Mobile" label="手机号" width="100" align="left"></el-table-column>
                            <el-table-column prop="Operate_User" label="操作人"  min-width="80"></el-table-column>
                            <el-table-column prop="Operate_Time" label="操作时间" :formatter="dateFormat"  width="140"></el-table-column>
                        </el-table>
                    </div>
                    <div class="page-box">
                        <span>当前第 {{tablePageIndex}} 页 &nbsp;&nbsp;&nbsp;&nbsp; 共{{tablePageSum}}页</span>
                        <div class="page-button">
                            <page-Turning class="but but-up" type="up" v-model="tablePageIndex" :pageCount="tablePageSum">上一页</page-Turning>
                            <page-Turning class="but but-down" type="down" v-model="tablePageIndex" :pageCount="tablePageSum">下一页</page-Turning>
                        </div>
                    </div>
                </div>
                <div class="preview">
                    <div class="scroll-box" v-mouse-scroll v-html="Print_Html">
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-box">
            <button class="btn btn-cancel" @click="hide()">返回</button>
        </div>
    </modal>
</template>

<script>
// 卡资产调整记录
export default {
    name:'cardReturnRecordModel',
    emits:["closeModel"],
    props:{
        isShow:{
            type:Boolean,
            default:false
        },
    },
    data(){
        return{
            searchText:"",
            //是否是当前营业日期
            isRptDate:false,
            /**营业日期 */
            businessHours:new Date(),
            tablePageSize:9,
            tablePageIndex:1,
            /**数据 */
            tableList:[],
            //排序用
            sortable:undefined,
            /**勾选的数据 */
            checkArr:[],
            //选中单个
            currentRow:null,
            cardAdjusts:[]
        }
    },
    computed:{
        Print_Html(){
            return this.cardAdjusts?.join('<br/>');
        },
        tableFilter(){
            let list=this.tableList||[];
            if(list && list.length>0){
                if(this.searchText){//搜索
                    let seach=this.searchText.toUpperCase();
                    list=list.filter((item)=>(item.Card_No||'').toUpperCase().indexOf(seach)>=0 
                        || (item.Mobile||'').toUpperCase().indexOf(seach)>=0 
                        || (item.Member_Name||'').toUpperCase().indexOf(seach)>=0)
                }
            }
            //排序
            if(this.sortable){
                list=Object.assign([],this.tableList);
                let sorttype=this.sortable.order=='ascending'?'asc':'desc';
                list.order(this.sortable.prop,sorttype);
            }
            return list;
        },
        /**分页数据 */
        tablePageList(){
            if(this.tableFilter && this.tableFilter.length>0){
                 return this.tableFilter.slice(((this.tablePageIndex-1)*this.tablePageSize),(this.tablePageIndex)*this.tablePageSize);
            }
            return [];
        },
        //分页总页数 
        tablePageSum(){
            let lenth=1;
            if(this.tableFilter && this.tableFilter.length>0){
                lenth=Math.ceil(this.tableFilter.length/this.tablePageSize)||1;
            }
            return lenth;
        },
    },
    mounted(){
        this.$nextTick(()=>{
            this.init();
        });
    },
    watch:{
        isShow(newVal){
            if(newVal){
                this.init()
            }
        },
        tablePageSum(){
            if(this.tablePageSum<this.tablePageIndex){
                this.tablePageIndex=1;
            }
        }
    },
    methods:{
        /*时间格式 */
        dateFormat(row, column, cellValue) {
            if(!cellValue || cellValue=='0001-01-01T00:00:00'){
                return '';
            }
            return (new Date(cellValue)).Format('yyyy-MM-dd hh:mm:ss');
        },
        //选中行
        currentChange(row){
            this.currentRow=row;
            if(row){
                try {
                    this.cardAdjusts= JSON.parse(this.currentRow?.Biz_Remark)?.CardAdjusts;
                } catch (error) {
                    this.cardAdjusts=[];
                }
               
            }else{
                this.cardAdjusts=[];
            }
        },
        /**初始化数据 */
        init(){
            this.cardNo=''
            let userInfo= this.$auth.getUserInfo();
            if(userInfo){//营业日期
                let rptDate=new Date(userInfo.Rpt_Date);
                this.businessHours=new Date(rptDate.setDate(rptDate.getDate()));
            }
            this.loadTable();
        },
        /**获取会员业务流水记录 */
        loadTable(){
            const loading = this.$loading({
                text: "数据加载中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.tableList=[];
            let param={
                Rpt_Date:(new Date(this.businessHours)).Format('yyyy-MM-dd'),
                Biz_Type:15, //15 卡资产调整业务
                Operate_User:userInfo?.Login_Name,//操作员
                Operate_PosName:userInfo?.Site_Name, //操作站点
            }
            this.$httpAES.post("Bestech.CloudPos.GetCrmBizRecords",param).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    this.tablePageIndex=1;
                    this.tableList=d.ResponseBody||[];
                }else{
                    this.$message.error(d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                console.log('加载厨房单信息数据失败：',e);
            })
        },
        /**重印小票 */
        printReceipt(item){
            const loading = this.$loading({
                text: "请求打印数据中...",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.7)",
            });
            let userInfo= this.$auth.getUserInfo();
            this.$httpAES.post("Bestech.CloudPos.GetMemberBizPrintContent",{
                Operate_User:userInfo?.Login_Name,
                User_ID:userInfo?.User_ID,
                Biz_Type:3,//卡资产调整业务
                Biz_ID:item.Crm_BizID
            }).then((d)=>{
                loading.close();
                if(d.ResponseHeader.ResultCode==0){
                    if(d.ResponseBody){
                        this.$webBrowser.posPrint(d.ResponseBody);
                        this.$message.success("已发送打印指令");
                    }else{
                        this.$message.error('请求设备没有相关打印设置!');
                    }
                    
                }else{
                    this.$message.error("请求打印数据失败："+d.ResponseHeader.ResultDesc);
                }
            }).catch((e)=>{
                loading.close();
                this.$message.error('请求打印数据失败：'+e);
                console.log('请求打印数据失败：'+e);
            })
        },
        hide(){
            this.$emit("closeModel");
        }
    }
}
</script>

<style lang="scss">
@import './cardAssetRecordModel.scss'
</style>